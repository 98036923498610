import React from 'react'
import TrainingTemplate from 'components/templates/TrainingTemplate/TrainingTemplate'

export default function TrainingRlsPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Rls'

  const description =
    'Il Rappresentante dei Lavoratori per la Sicurezza (RLS) ha diritto ad una formazione  in materia di salute e sicurezza fissata  dal D. Lgs. 81/08. Tale formazione deve permettere al RLS di poter raggiungere adeguate conoscenze circa i rischi lavorativi esistenti negli ambiti in cui esercita la propria rappresentanza, adeguate competenze sulle principali tecniche di controllo e prevenzione dei rischi stessi, nonché principi giuridici comunitari e nazionali, legislazione generale e speciale in materia di salute e sicurezza sul lavoro, principali soggetti coinvolti e relativi obblighi e aspetti normativi della rappresentanza dei lavoratori e tecnica della comunicazione.<br> La durata minima dei corsi è di 32 ore.<br><br> Annualmente è previsto un aggiornamento minimo di 4 ore in funzione delle condizioni di rischio dell’azienda di appartenenza del RLS.'

  const description1 = null

  return (
    <>
      <TrainingTemplate
        seoKeywords={seoKeywords}
        productName={productName}
        description={description}
        description1={description1}
      />
    </>
  )
}
